/**
 * Charity chooser JS. Supports choosing a charity or a fundraising page.
 *
 * @author Daniel Moffat
 */
'use strict';

var CharityConsentModal = require('Scripts/common/charity-consent-modal')
var miuri               = require('Vendor/miuri.js').miuri;
var Pagination          = require('Scripts/common/pagination');

module.exports = CharityChooser;

// Constants
var DEFAULT_SORT          = 'POPULARITY_DESCENDING';
var DEFAULT_CATEGORY      = 0;
var ACTIVE_CATEGORY_CLASS = 'active';

function CharityChooser(args) {

	this.init = function(args) {
		// These control how the "Support us" button behaves on each site. Most versions just use hrefs, but if JS needed this decides the function to use
		this.defaultHandlers = {
			...(args && args.handler),
			'admin-campaign-charity': this.multiCharity,
			'admin-ticketing-charity': this.multiCharity,
			'corporate-campaign-charity': this.multiCharity,
			'change-charity': this.changeCharity,
			'select-page-charity': this.multiCharity,
		};

		this.ui = {
			categories: {
				items: $('.js-search-result-category'),
				list: $('.js-search-result-category-list'),
				modal: $('#modal-charity-categories'),
				removeButton: $('.js-search-result-category-remove'),
				selected: $('.js-selected-category'),
			},
			charityConsentModal: new CharityConsentModal(),
			confirmModal: $('#js-modal-confirm-charity'),
			entityName: $('.js-charity-chooser-entity'),
			featuredCharity: $('.js-featured-charity'),
			modal: $('#js-modal-select-charity'),
			root: $('.js-charity-chooser-root'),
			searchBox: $('.js-charity-chooser-form').find('#q'),
			searchForm: $('.js-charity-chooser-form'),
			searchResults: {
				content: $('.js-search-result-content'),
				failedRequest: $('.js-failed-request'),
				list: $('.js-search-results-list'),
				items: $('.js-search-result'),
				loading: $('.js-loading-indicator'),
				pagination: $('.js-pagination')
			},
			sort: $('.js-search-sort'),
		};

		this.pagination = new Pagination({
			onClickPage: this.onSelectPagination.bind(this)
		});

		var rootData = this.ui.root.data();

		// Contains the state of the charity chooser (what query has been entered, which filters have been applied etc.)
		this.state = Object.assign(new miuri().query(), {
			// Two different modes which specify the thing being chosen, either 'charity' or 'fundraising-page'. Stored as a query param
			// so we can support back/forward between modes.
			mode: rootData['mode'] || 'charity',
			source: rootData['source']
		});

		// Set a couple more defaults (query if it's been set by the VM instead of a query string, prefix for adjusting search terms)
		$.each(["q", "categoryId", "prefix"], function(i, name){
			if(!(name in self.state) && rootData[name]) {
				self.state[name] = rootData[name];
			}
		})

		// Contains the currently selected cause/fundraising page data, populated when user clicks a search result.
		this.selectedData = null;

		// Called when a user clicks the "Support us" button.
		this.handler = this.getHandlerFunction();

		console.log('Charity chooser init with state:', this.state);

		this.bindEventHandlers();
	};

	// Gets the handler function for this website / source, if there's not an explicit function defined in 'this.handlers',
	// it will take a function from 'this.defaultHandlers'. If it still couldn't be found it will throw an error.
	this.getHandlerFunction = function() {
		console.log('Getting handler function siteId=' + this.ui.root.data('siteId') + ', source=' + this.state.source);
		return this.defaultHandlers[this.state.source] || null;
	};

	this.bindEventHandlers = function() {
		this.ui.root.on('change', '.js-search-sort', this.onSortChanged.bind(this));
		this.ui.root.on('click', '.js-search-result', this.onSelectResult.bind(this));
		this.ui.root.on('click', '.js-reset-search', this.onResetSearch.bind(this));
		this.ui.root.on('click', '.js-change-mode', this.onChangeMode.bind(this));
		this.ui.categories.list.on('click', '.js-search-result-category', this.onSelectCategory.bind(this));
		this.ui.categories.removeButton.on('click', this.onRemoveCategory.bind(this));
		this.ui.featuredCharity.on('click', this.onClickFeaturedCharity.bind(this));
		this.ui.searchForm.on('submit', this.onSearch.bind(this));
		$(document).on('click', '.js-select-charity-modal', this.onSelectCharity.bind(this));
		$(document).on('click', '.js-remove', this.onRemoveCharity.bind(this));
		$(window).on('popstate', this.onUrlChanged.bind(this));
	};

	// Booleans
	this.isFP = function() { return this.state.mode === "fundraising-page" };
	this.isCharity = function() { return this.state.mode === "charity" };

	// Gets called when you click inside a search result. It picks up everything so we can reliably populate
	// 'this.selectedData' without needing to do it differently for different situations (i.e. modal vs main results).
	this.onSelectResult = function(e) {
		console.log('Selected search result');

		// Extract the cause data from the element that was clicked.
		this.selectedData = this.extract($(e.currentTarget));
		console.log(this.selectedData);

		// User clicked the primary button, skip the modal step.
		if($(e.target).hasClass('btn-green') || $(e.target).hasClass('btn-navy')) {
			console.log('User clicked primary button, skip modal');
			this.onSelectCharity();
			return;
		}

		// Otherwise display the modal step.
		this.openModal();
		e.preventDefault();
	};

	// Gets called when the user clicks the main "Support us" button (either in the modal or on the list.)
	this.onSelectCharity = function() {
		console.log('onSelectCharity');
		if($.isFunction(this.handler)) {
			this.handler(this.selectedData);
		}
	};

	this.onClickFeaturedCharity = function(e) {
		console.log('onClickFeaturedCharity');
		this.selectedData = this.extract($(e.currentTarget))
		this.openModal();
	};

	// Happens on page click. 
	this.onSelectPagination = function(page) {
		console.log("onSelectPagination");
		event.preventDefault();
		this.state.page = page;
		this.doSearch(true);
	};

	this.onResetSearch = function(e) {
		e.preventDefault();
		var clickedEl = $(e.currentTarget);
		var categoryId = clickedEl.data('categoryId');
		this.ui.searchBox.val("");

		if(categoryId) {
			console.log('Resetting search to category: ' + categoryId);
			this.resetSearchToCategory(categoryId);
		} else {
			console.log('Standard reset search.');
			this.resetSearch();
		}
	};

	this.onSearch = function(e) {
		this.resetPaging();
		e.preventDefault();
		var query = this.ui.searchBox.val().trim();
		console.log('onSearch(' + query + ')')
		this.state.q = query;
		this.doSearch(true);
	};

	this.onFailedRequest = function() {
		this.ui.searchResults.content.hide();
		this.ui.searchResults.failedRequest.show();
		this.ui.sort.hide();
	};

	// Called when user hits the forward / back buttons.
	this.onUrlChanged = function(event) {
		console.log('Forward or back button pressed. Trying to recreate state.');
		var newState = new miuri(document.location.href).query();
		console.log(newState);
		this.state = newState;
		this.state.mode = this.state.mode || 'charity';
		this.state.source = this.ui.root.data('source'); // Has to be set again otherwise wrong stuff returned
		this.state.prefix = this.ui.root.data('prefix'); // Has to be set again otherwise wrong stuff returned
		this.setQuery(this.state.q)
		this.setActiveCategory(this.state.categoryId || DEFAULT_CATEGORY);
		this.setSort(this.state.sort);
		this.doSearch(false);
	};

	this.onRemoveCategory = function() {
		this.removeActiveCategory();
		this.resetPaging();
		this.state.sort = null;
		this.setSort();
		this.selectAllCategories();
	};

	this.selectAllCategories = function() {
		this.ui.categories.list.children().first().click();
	};

	this.onSortChanged = function(e) {
		console.log('Sort changed.');
		this.resetPaging();
		var selectedSort = $(e.currentTarget).val();

		// Don't add a sort parameter to the URL if it's the default one.
		if(selectedSort === DEFAULT_SORT) {
			this.state.sort = null;
		} else {
			this.state.sort = selectedSort;
		}

		this.doSearch(true);
	};

	this.onSelectCategory = function(e) {
		// User clicked close button (which lives inside the category element)
		if($(e.target).hasClass('.js-search-result-category-remove')) {
			return;
		}

		var categoryId = parseInt($(e.currentTarget).data('categoryId'), 10);

		this.resetPaging();
		this.setActiveCategory(categoryId);
		this.doSearch(true);
	};

	this.onChangeMode = function(e) {
		var button = $(e.currentTarget);
		var newMode = button.data('mode');
		console.log('Changing mode to...' + newMode);
		assertValidMode(newMode);
		this.changeMode(newMode);
		button.hide().siblings().show();
	};

	this.changeMode = function(newMode) {
		this.state.mode = newMode;
		this.resetFilters();
		this.updateEntityName();
		var query = this.ui.searchBox.val().trim();
		this.state.q = query;
		this.doSearch(true);
	};

	// Resets all of the filters back to their default values.
	this.resetFilters = function() {
		// Update the UI
		this.setActiveCategory(DEFAULT_CATEGORY);
		this.setSort();

		// Update the state/query string
		this.state.q = null;
		this.state.sort = null;
		this.resetPaging();
		this.state.categoryId = null;
	};

	// Used on the main reset search button
	this.resetSearch = function() {
		this.resetFilters();
		this.doSearch(true);
	};

	// Always call this to reset paging, as we must update the paginator class's page variable as well
	this.resetPaging = function() {
		// The "state" page variable must be updated, since it gets encoded into the URL string
		this.state.page = null;
		// Another copy of page variable is inside the paginator, this also needs to be updated.
		this.pagination.changePage(1);
	}

	// Used on the reset search buttons which go to a specific category after reset.
	this.resetSearchToCategory = function(categoryId) {
		this.resetFilters();
		this.state.categoryId = categoryId;
		this.doSearch(true);
	}

	// Updates various bits of the UI with either "fundraising page" or "charity", depending on the mode.
	this.updateEntityName = function() {
		this.ui.entityName.each(function() {
			// Get old and new text
			var oldText = $(this).text();
			var newText = $(this).data('switch');

			// Switch them round
			$(this).text(newText);
			$(this).data('switch', oldText);
		});

		// Set the meta page title
		var pageTitle = document.title;
		var c = 'charity'; 
		var fp = 'Fundraising Page'; 
		document.title = this.isCharity() ? pageTitle.replace(fp,c) : pageTitle.replace(c,fp);
	};

	// Updates the search box with the given query - used when going back/forward to restore the search box.
	this.setQuery = function(query) {
		this.ui.searchBox.val(query);
	};

	// Sets the active category - used when going back/forward to restore the selected category + when selecting a category
	this.setActiveCategory = function(id) {
		this.removeActiveCategory();
		var category = $('[data-category-id="' + id + '"]');
		category.addClass(ACTIVE_CATEGORY_CLASS);
		category.append(this.ui.categories.removeButton);

		// Keep the state (aka query params) up to date.
		if(id !== DEFAULT_CATEGORY) {
			this.ui.categories.removeButton.show();
			this.ui.categories.selected.filter(category).show();
			this.state.categoryId = id;
		} else {
			this.state.categoryId = null;
		}
	};

	// Sets the sort - used when going back/forward to restore the selected sort.
	this.setSort = function(sort) {
		this.ui.sort.val(sort || DEFAULT_SORT);
	};

	this.removeActiveCategory = function() {
		this.ui.categories.items.removeClass(ACTIVE_CATEGORY_CLASS);
		this.ui.categories.removeButton.hide();
		this.ui.categories.selected.hide();
	};

	// Extracts data from an element, depending on the mode, different stuff is extracted.
	this.extract = function(el) {
		return {
			cid: el.data('cid'),
			cause: el.data('cause') || null,
			eid: el.data('eid') || null,
			title: el.data('title') || null,
			remove: el.data('remove') || 0,
		}
	};

	// Populates the consent modal form + changes some of the UI depending on the mode. TBH, this belongs in the email consent JS, it should have a method
	// which accepts some data and updates its own UI.
	this.populateConsentModal = function() {
		var data = this.selectedData;

		$("#email-consent__charity-id").val(data.cid);
		$(".js-charity-name").html(data.cause);

		if(this.isFP()) {
			$("#email-consent__fundraising-page-id").val(data.eid);
			$("#email-consent__fundraising-page-name").html(data.title);
			$('.js-fundraising-page-name').show();
		}
		else {
			$('.js-fundraising-page-name').hide();
		}
	};

	this.openModal = function() {
		var qs   = new miuri().query();
		var dest = new miuri('/select-charity-modal');
		var data = {...this.state, ...this.selectedData };

		$.ajax({
			url: dest, 
			data: data
		}).then(function(resp) { // resp is raw HTML.
			self.ui.modal.find('.modal-content').html(resp);
			self.ui.modal.modal('show');
			self.populateConsentModal();
		});		
	};

	this.doSearch = function(updateUrl) {
		console.log('Search with params', this.state);
		var searchResults = this.ui.searchResults;
		searchResults.failedRequest.hide();
		searchResults.content.hide();
		searchResults.loading.show();

		$.ajax({
			url: this.isCharity() ? '/cause/select' : '/fundraisingpage/select',
			method: "GET",
			data: this.state
		}).then(function(resp) {
			searchResults.loading.hide();
			searchResults.content.empty().html(resp).show();
			this.pagination.bindEventHandlers(); // Rebind pagination after it gets destroyed.
			this.ui.categories.modal.modal('hide');

			if(updateUrl) {
				this.updateUrl();
			}
		}.bind(this));
	};

	// Updates the URL based upon the filter that's been selected.
	this.updateUrl = function() {
		var newUrl = new miuri(new miuri().path());

		// Add the state variables to the URL, but excludes specific values
		for(var key in this.state) {
			if($.inArray(key, ['source', 'prefix']) == -1) {
				newUrl.query(key, this.state[key]);
			}
		}

		if(!this.ui.root.parents(".modal").length) {
			console.log('Updating URL to ' + newUrl.toString());
			window.history.pushState({}, '', newUrl.toString());
		}
	};

	// Default change charity function, used by all websites.
	this.changeCharity = function(data) {
		console.log('changeCharity(charityId=' + data.cid + ', eid=' + data.eid + ')');
		this.populateConsentModal();
		this.ui.charityConsentModal.show();
	};

	// If creating a fundraising page, display the confirmation screen after so they can add additional charities
	this.multiCharity = function(data) {
		console.log('multiCharity(charityId=' + data.cid + ')');
		
		var confirmModal = this.ui.confirmModal;
		var confirmModalContents = confirmModal.find('.modal-content');

		var rurl = this.ui.root.data("rurl");
		if(rurl){
			data['rurl'] = rurl;
		}

		$.ajax({
			url: this.ui.root.data().multiConfirmEndpoint,
			method: "GET",
			data: data
		}).then((html, textStatus, response) => {

			this.ui.modal.modal('hide');

			// Do we want to show or hide the modal and confirm bar
			var showHide = html.length > 0 ? 'show' : 'hide';

			confirmModalContents.empty().html(html);
			confirmModal.modal(showHide);

			// Update the alert bar
			var selectedCharitiesAlert = $('#multibar');			

			if(showHide == 'show'){
				var selectedCharitiesData = confirmModal.find('#multidata').data();
				selectedCharitiesAlert.find('.list').html(selectedCharitiesData.names)
				selectedCharitiesAlert.find('.btn').attr('href', selectedCharitiesData.url)
			}

			selectedCharitiesAlert.collapse(showHide);

			// Toggle the buttons
			if(!response.getResponseHeader("charityLimitExceeded")) {
				$("[data-cid=" + data.cid + "] .collapse").collapse("toggle");
			}
		});
	}

	// If removing charity, just get data then jump to multi-charity process
	this.onRemoveCharity = function(e) {
		e.preventDefault();
		var data = this.extract($(e.currentTarget));
		this.multiCharity(data);
	}

	var self = this;
	self.init(args);
}

function assertValidMode(mode) {
	if(mode !== 'charity' && mode !== 'fundraising-page') {
		throw new Error('Invalid mode: ' + mode, 'expected: fundraising-page or charity');
	}
}