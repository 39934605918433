'use strict';

import Cropper from 'Vendor/cropperjs';

export default class ImageUploadCrop {
	constructor(options) {
		if(!options.width || !options.height) {
			console.log("ImageUploadCrop.constructor()", "No width/height defined");
			return;
		}

		this.maxSize    = 10000000;
		this.width      = options.width;
		this.height     = options.height;
		this.flexible   = options.flexible || false;
		this.fileTypes  = ['image/jpeg','image/png','image/bmp','image/gif','image/webp','image/webp', 'image/svg+xml'];
		this.callback   = options.callback || false;
		this.restrict   = options.restrict;
		this.isVector   = false;

		const container = options.container || $("main");
		const modal     = options.modal || $('#js-modal-image-crop');
		
		this.ui = {
			cropBtn:             modal.find('.js-do-crop'),
			cropModal:           modal,
			cropCentre:          modal.find('.js-crop-centre'),
			cropReset:           modal.find('.js-crop-reset'),
			cropZoom:            modal.find('.js-crop-zoom'),
			cropResult:          container.find('.js-crop-result'),
			cropResultContainer: container.find('.js-crop-result-container'),
			dnd:                 container.find('.dragdrop-file'),
			errorContainer:      container.find('.js-error-container'),
			imageInput:          container.find('.js-image-input'),
			imageToCrop:         modal.find('.js-image-crop'),
		};

		this.invalidTypeErrorMsg = this.ui.imageInput.data('invalidTypeErrorMsg');
		this.tooBigMsg           = this.ui.imageInput.data('tooBigMsg');
		this.tooSmallMsg         = this.ui.imageInput.data('tooSmallMsg');
		this.labelText           = container.find('.dragdrop-file-label').html();

		this.bindEventHandlers();
	}

	bindEventHandlers() {
		this.ui.imageInput.on('change', this.onChangeImage.bind(this));
		this.ui.cropModal.on('shown.bs.modal', this.onCropModalDisplayed.bind(this));
		this.ui.cropModal.on('hide.bs.modal', this.onCropModalClosed.bind(this)); // Need to use hide rather than hidden as a function resets that event later

		this.ui.cropBtn.on('click', this.onCrop.bind(this));
		this.ui.cropReset.on('click', this.onResetZoom.bind(this));
		this.ui.cropCentre.on('click', this.onCentreZoom.bind(this));
		this.ui.cropZoom.on('click', this.onManualZoom.bind(this));

		this.ui.imageInput.on('drag dragstart dragend dragover dragenter dragleave drop', e => {
			this.ui.dnd.addClass("dragdrop-file-hover");
		})
		.on('dragleave dragend drop', e => {
			this.ui.dnd.removeClass('dragdrop-file-hover');
		});
	}

	async onChangeImage() {
		console.log("ImageUploadCrop.onChangeImage()");

		this.reset(); // Always reset the state after an image has been selected.

		this.selectedFile = this.ui.imageInput.get(0).files[0];

		if(!this.selectedFile) { // User cleared the image input
			return;
		}

		this.updateFileInputLabel('Verifying image');

		// Is file format ok?
		if(!this.isValidContentType(this.selectedFile)) {
			this.showErrorMsg(this.invalidTypeErrorMsg);
			return;
		}

		// Is file size ok?
		if(!this.isValidSize(this.selectedFile)) {
			this.showErrorMsg(this.tooBigMsg);
			return;
		}

		this.isVector = this.selectedFile.type == 'image/svg+xml';

		// Are dimensions ok? (skip if vector)
		if(!this.isVector && ! await this.isValidDimensions(this.selectedFile)) {
			this.showErrorMsg(this.tooSmallMsg);
			return;
		}

		this.updateFileInputLabel('Selected: ' + this.selectedFile.name);

		this.displayImageToCrop();
	}

	isValidContentType(file) {
		return this.fileTypes.indexOf(file.type) > -1;
	}

	async isValidDimensions(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = (e) => {
				let image = new Image();
				image.src = e.target.result;
				image.onload = (e) => {
					let width = e.target.width;
					let height = e.target.height;

					console.log({width: width, height: height, flexible: this.flexible})
					
					// Check that both dimensions
					let validDimensions = (!this.width || width >= this.width) && (!this.height || height >= this.height);
					
					// If we allow flexible dimensions then one of the dimensions needs to be 80% - works well for logos as the remaining space will just be whitespace
					let bigEnough = (!this.width && !this.height) || (this.width && width >= (0.8 * this.width)) || (this.height && height >= (0.8 * this.height));

					console.log({validDimensions: validDimensions, bigEnough: bigEnough});

					resolve(this.flexible ? bigEnough : validDimensions);
				};
			};
		});
	}

	isValidSize(file) {
		return file.size <= this.maxSize;
	}

	// Updates the file input label to the given message
	updateFileInputLabel(msg) {
		var msg = msg || this.labelText;
		var labelEl = this.ui.imageInput.next();
		labelEl.html(msg);
	};

	displayImageToCrop() {
		console.log("ImageUploadCrop.displayImageToCrop()", {isVector: this.isVector});
		let reader = new FileReader();
		
		reader.onload = (e) => {
			this.ui.imageToCrop.attr('src', e.target.result);

			// Specify a higher width if it's vector, otherwise it might be displayed really small
			if(this.isVector) {
				this.ui.imageToCrop.attr('width', this.width * 10);
				this.ui.imageToCrop.attr('height', 'auto');
			}

			// When the image has been read, show the modal.
			this.ui.cropModal.modal('show');
		};
		
		reader.readAsDataURL(this.selectedFile);
	}

	onCropModalDisplayed() {
		console.log("ImageUploadCrop.onCropModalDisplayed()");
		this.initCropper(this.ui.imageToCrop.get(0));
	}

	onCropModalClosed() {
		console.log("ImageUploadCrop.onCropModalClosed()");
		if(!this.croppedImage) {
			this.updateFileInputLabel();
		}

		// Needed in case you close the modal (without cropping) and then try to upload the same image as before
		this.ui.imageInput.val(null);

		// Reset the height - although not technically needed here, it stops the height change flashing when re-opening the modal
		this.toggleHeightOverride(true);
	}

	initCropper(element) {
		console.log("ImageUploadCrop.initCropper()");
		let width = this.width;
		let height = this.height;

		this.toggleHeightOverride(true);
		
		// Destroy any existing cropper instance.
		if(this.cropper) {
			this.cropper.destroy();
		}

		this.cropper = new Cropper(element, {
			aspectRatio: width / height,     // Keeps it the right size
			autoCropArea: 1,                 // When first loads, 
			center: false,                   // Hide crosshair
			cropBoxMovable: this.restrict,   // Stops you moving the cropbox
			cropBoxResizable: false,         // Stops you resizing the cropbox
			dragMode: 'move',                // You move/drag the image rather than the cropbox
			guides: false,                   // Hide dashed lines
			maxCropBoxHeight: height,        // Height
			maxCropBoxWidth: width,          // Width
			toggleDragModeOnDblclick: false, // Stops you being able to change to move/drag the cropbox
			viewMode: this.restrict ? 3 : 0, // 3 = Stops you making the image smaller than the cropbox
		});

		// This prevents over-zooming
		$(element).on('zoom', this.onZoom.bind(this));
		
		// Trigger a zoom on load to prevent initial over-zooming. The baseZoom is used later for resetting the zoom
		$(element).on('ready', e => {
			this.onZoom()

			this.baseZoom = this.cropper.canvasData.width / this.cropper.canvasData.naturalWidth;

			this.toggleHeightOverride(false);
		});
	}

	onZoom(e) {
		let curRatio = typeof e !== 'undefined' ? e.detail.ratio : this.cropper.canvasData.width / this.cropper.canvasData.naturalWidth;
		let maxRatio = this.cropper.cropBoxData.width / this.cropper.options.maxCropBoxWidth;

		if(!this.isVector && curRatio > maxRatio) {
			this.cropper.zoomTo(maxRatio);
			event.preventDefault();
			console.log("ImageUploadCrop.onZoom", "Don't zoom in");
		}
	}

	onManualZoom(e) {
		var type = $(e.currentTarget).data('type');
		this.cropper.zoom(type == "in" ? +0.05 : -0.05);
	}

	onCrop() {
		console.log("ImageUploadCrop.onCrop()");

		this.cropper.getCroppedCanvas({
			fillColor: '#fff',
			height: this.height,
			width: this.width,
			minHeight: this.height,
			minWidth: this.width,
			maxHeight: this.height * 2, // Setting to this.height can cause blurryness, omitting it causes issues with v.large PNGs
			maxWidth: this.width * 2, // Setting to this.height can cause blurryness, omitting it causes issues with v.large PNGs
			imageSmoothingEnabled: true,
			imageSmoothingQuality: "high",
		}).toBlob(blob => {
			// Add the blob to a variable for use later on
			this.croppedImage = blob;
			console.log("ci", this.croppedImage);
			this.displayCroppedImage(blob);
			this.ui.cropModal.modal('hide');
		}, 'image/jpeg', 1);
	}

	displayCroppedImage(blob) {
		console.log("ImageUploadCrop.displayCroppedImage()");
		
		var url = URL.createObjectURL(blob);
		this.ui.cropResult.attr('src', url);

		// Reveal the container with cropped image in - it may have been hidden previously.
		this.ui.cropResultContainer.addClass('selected').collapse('show');

		// Callback if provided - as the callback could invoke a modal, we need to ensure events don't overlap, hence the on/off bindings
		if(this.callback) {
			let event = 'hidden.bs.modal';
			this.ui.cropModal.on(event, () => {
				this.callback();
				this.ui.cropModal.off(event);
			});
		}
	}

	reset() {
		console.log("ImageUploadCrop.reset()");
		this.croppedImage = null;
		this.ui.cropResult.attr('src', this.ui.cropResult.data('spacer') || '');
		this.ui.cropResultContainer.collapse('hide');
		this.clearErrorMsg();
		this.updateFileInputLabel();
	}

	onResetZoom() {
		console.log("ImageUploadCrop.onResetZoom()");
		this.cropper.zoomTo(this.baseZoom || 100);
	}

	onCentreZoom() {
		console.log("ImageUploadCrop.onCentreZoom()");
		let container = this.cropper.containerData;
		let image = this.cropper.imageData;
		this.cropper.moveTo((container.width / 2) - (image.width / 2), (container.height / 2) - (image.height / 2));
	}

	showErrorMsg(msg) {
		console.log("ImageUploadCrop.showErrorMsg()", msg);
		this.ui.errorContainer.empty().text(msg).show();
		this.updateFileInputLabel();
	}

	clearErrorMsg() {
		console.log("ImageUploadCrop.clearErrorMsg()");
		this.ui.errorContainer.empty().hide();
	}

	addImagesToFormData(formData, prepend) {
		console.log("ImageUploadCrop.addImagesToFormData()", prepend);

		if(this.selectedFile && this.croppedImage) {
			prepend = prepend != null ? prepend + "." : "";
			formData.append(prepend + "originalImage", this.selectedFile);
			formData.append(prepend + "croppedImage", this.croppedImage);
		}

		return formData;
	}

	// Make the modal full-height - this allows CropperJS to calculate the available size so that the image fits the modal correctly
	toggleHeightOverride(toggleOn) {
		$(".modal-cropper .modal-content").css("height", toggleOn ? "100%" : "auto");
	}
};