/**
 * Simple form module that can be reused lots
 * 
 * Some useful bits of info that aren't immediately obvious:
 * - Your submit button needs a `.js-submit` class
 * - 'preCheck' must return true to actually continue form submission, use 'before' to execute some code each
 * time the form is submitted. 'addToForm' sort of does that, but that also does something with the return value.
 * 
 */

import AjaxFormErrorHandler from "Scripts/common/ajax-form-error-handler";
import LoadingButton        from 'Scripts/common/loading-button';

// Function used when options which require functions aren't present.
const NO_OP_FUNCTION = function() { 
	/* does nothing */
}

export default class Form {
	constructor(options) {
		if(options) {
			this.setup(options);
		}
	}

	// Set up the object
	setup(options) {
		options    = options || this.options || {};
		let form   = $(options.form);
		let button = options.button || form.find(".js-submit");

		if(!form || !form.length) {
			console.warn("Form.setup()", "No form found")
			return false;
		}

		this.options = {
			addToForm:    options.addToForm || null,                  // A function that runs just before submission, to add additional data to the request (e.g. cropper images or additional data in a modal)
			always:       options.always || null,                     // A function that runs afterwards, when the submission is success or not
			button:       button ? new LoadingButton(button) : false, // The submit button for the form
			callback:     options.callback || null,                   // A function that runs after a successful form submission
			endpoint:     options.endpoint || form.attr('action'),    // Endpoint for the form submission - will usually be taken from the form element but can be manually passed too
			errorHandler: new AjaxFormErrorHandler({parent:form}),    // Error handling
			form:         form,                                       // The <form> element
			preCheck:     options.preCheck || null,                   // A function to run before the submission starts, e.g. Recaptcha (see Login-Signup.js)
			willRedirect: options.willRedirect || false,              // Indicates if the page will redirect if successful - this is used to enable the success state of the submit button
			onError:      options.onError || NO_OP_FUNCTION,		  // A function that's called when an error is thrown in either the response -> JSON conversion, or any 'then' blocks
			before: 	  options.before || NO_OP_FUNCTION			  // A function that always executes before form submission. Useful for cleaning up form errors + other bits
		};

		console.log("Form.setup()", options, this.options);

		form.on('submit', this.submit.bind(this));
	}

	submit(e) {
		e.preventDefault();

		if(this.options.preCheck && !this.options.preCheck()) {
			console.log(`Form.submit', 'Halting form submission because #preCheck didn't return true.`);
			return;
		}
		
		this.changeButton(false);

		let formData = new FormData(e.currentTarget);

		if(this.options.addToForm) {
			formData = this.options.addToForm(formData);
		}

		console.log('Form data:');
		for(let pair of formData.entries()) {
			console.log(`name=${pair[0]}, value=${pair[1]}`);
		}

		this.options.before();

		fetch(this.options.endpoint, {
			body: formData,
			method: 'POST',
		})
		.then(response => {
			if(response.ok) {
				return response.json();
			}
			throw new Error("Not 2xx response")
		}, error => {
			console.error('Error:', error);
			this.changeButton(true);
		})
		.then(data => {
			console.log("Form.submit.data", data);
			
			let validation = data.payload || data;
			validation = validation.validation || data;

			let globalError = $("#oops");

			if(this.options.errorHandler.handleErrors(validation)) {
				globalError.show();
			}
			else {
				globalError.hide();

				// If reloading the page, this ensure we go to the top
				history.scrollRestoration = 'manual';

				if(this.options.callback) {
					this.options.callback(data);
				}
			}

			if(this.options.always) {
				this.options.always(data);
			}

			if(this.options.willRedirect && validation.success) {
				this.successButton(true);
			}
			else {
				this.changeButton(true);
			}
		})
		.catch(err => {
			// Not sure if this is the right behaviour, maybe if onError isn't defined you could re-throw it so it's surfaced to Sentry etc
			console.error(err);
			this.options.onError(err);
			this.enableButton();
		});
	}

	enableButton() {
		this.changeButton(true);
	}

	changeButton(enabled) {
		let button = this.options.button;
		if(button) {
			enabled ? button.enable() : button.disable();
		}
	}

	successButton() {
		let button = this.options.button;
		if(button) {
			button.success();
		}
	}
}
