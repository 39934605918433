import Form from 'Scripts/common/form';

export default class DonateSettingsForm {
    constructor() {
        this.form = new Form({
            form: $('.js-donate-settings-form'),
            callback: (data) => {
                window.location.reload();
            }
        });
    } 
}