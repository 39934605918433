import AjaxFormErrorHandler   from 'Scripts/common/ajax-form-error-handler';
import fetchJson              from 'Scripts/common/fetch-json';
import ImageUploadCrop        from 'Scripts/common/image-upload-crop';
import ImageUploadGallery     from 'Scripts/common/image-upload-gallery';
import LoadingButton          from 'Scripts/common/loading-button';
import showErrorBar           from 'Scripts/common/show-error-bar';
import Slugify                from 'Vendor/slugify';
import TextAreaExpand         from 'Scripts/common/text-area-expand';
import UriAvailabilityChecker from 'Scripts/donate/uri-availability-checker';

export default class TicketingForm {
	constructor() {
		this.ui = {
			form: $(".js-ticketing-page-form"),
			advForm: $("#js-adv-ticketing-form"),

			// Containers
			dateContainer:      $('.js-date-container'),
			dateRangeContainer: $('.js-date-range-container'),
			privacyTextContainer: $('.js-privacy-text'),
			privacyUrlContainer: $('.js-privacy-url'),
			termsTextContainer: $('.js-terms-text'),
			termsUrlContainer: $('.js-terms-url'),

			// Inputs
			inputEventDate:      $('#eventDate'),
			inputEventStartDate: $('#eventStartDate'),
			inputEventEndDate:   $('#eventEndDate'),

			// Toggles
			toggleDateRange: $('.js-toggle-date-range'),
			togglePrivacy: $('.js-toggle-privacy'),
			toggleTerms: $('.js-toggle-terms'),
		}

		this.ui.submitBtn = new LoadingButton(this.ui.form.find('.js-submit'));
		this.errorHandler = new AjaxFormErrorHandler({ useClasses: true });

		// The banner
		this.image = new ImageUploadCrop({
			width:     1170,
			height:    350,
			restrict:  true,
		});

		// The banner gallery 
		this.gallery = new ImageUploadGallery({
			cropper: this.image,
			preview: $(".js-crop-result"),
			previewContainer: $(".js-crop-result-container"),
		});

		// URI checker
		new UriAvailabilityChecker('.js-uri-lookup', '/charity/ticketing/uri-availability');

		this.bindEventHandlers();
	}

	bindEventHandlers() {
		this.ui.toggleDateRange.on('change', this.onToggleDateRange.bind(this));
		this.ui.togglePrivacy.on('change', this.onTogglePrivacy.bind(this));
		this.ui.toggleTerms.on('change', this.onToggleTerms.bind(this));
		this.ui.form.on('submit', this.onSubmit.bind(this));
	}

	// Allow user to switch between single date and start/end dates
	onToggleDateRange() {
		const isRange    = this.ui.toggleDateRange.is(":checked");
		const singleDate = this.ui.inputEventDate;
		const rangeDates = this.ui.inputEventStartDate.add(this.ui.inputEventEndDate);

		if(isRange){
			rangeDates.val(singleDate.val());
		} else {
			singleDate.val(rangeDates.first().val());
		}

		singleDate.prop('disabled', isRange);
		rangeDates.prop('disabled', !isRange);

		this.ui.dateContainer.collapse(isRange ? 'hide' : 'show');
		this.ui.dateRangeContainer.collapse(isRange ? 'show' : 'hide');
	}

	onTogglePrivacy() {
		const toggleValue = this.ui.togglePrivacy.filter(":checked").val();
		console.log("TicketingForm.onTogglePrivacy()", toggleValue)
		this.ui.privacyUrlContainer.collapse(toggleValue == 'URL' ? 'show' : 'hide');
		this.ui.privacyTextContainer.collapse(toggleValue == 'TEXT' ? 'show' : 'hide');
	}

	onToggleTerms() {
		const toggleValue = this.ui.toggleTerms.filter(":checked").val();
		console.log("TicketingForm.onToggleTerms()", toggleValue)
		this.ui.termsUrlContainer.collapse(toggleValue == 'URL' ? 'show' : 'hide');
		this.ui.termsTextContainer.collapse(toggleValue == 'TEXT' ? 'show' : 'hide');
	}
	
	async onSubmit(e) {
		console.log("TicketingForm.onSubmit()");

		e.preventDefault();
		this.ui.submitBtn.disable();

		const { success, result } = await fetchJson($(e.currentTarget).attr('action'), {
			method: 'POST',
			body: this.getFormData(),
		});

		if(!success) {
			showErrorBar();
		}

		this.handleErrors(result);

		if(result.success) {
			console.log('Submission success', result.payload.redirectUrl);
			this.ui.submitBtn.success();
			window.location.href = result.payload.redirectUrl;
		} else {
			console.log('Submission failed');
			this.ui.submitBtn.enable();
		}
	}

	// Manually add some special data to the form
	getFormData() {
		let formData = new FormData(this.ui.form.get(0));
		formData = this.image.addImagesToFormData(formData, "images");

		// Add data from the advanced modal
		let advFormData  = new FormData(this.ui.advForm.get(0));
		for (let pair of advFormData.entries()) {
			formData.append(pair[0], pair[1]);
		}

		return formData;
	}

	handleErrors(data) {
		if(!data.success) {
			this.errorHandler.handleErrors(data.payload?.validation);
		} else {
			this.errorHandler.clear();
		}
	}
}