/**
 * A wrapper for JS's native fetch() which deals with errors
 * 
 * Example usage:
 * 
 * const { success, result } = await fetchHtml($(e.currentTarget).attr('action'), {
 *		method: 'POST',
 *		body: this.getFormData(),
 * });
 *
 * if(!success) {
 * 		// Do something (e.g. showErrorBar() using show-error-bar.js) 
 * }
 */

import AnalyticsEvent from 'Scripts/common/analytics-event';

export default async function fetchHtml(endpoint, fetchArgs) {
	try {
		const response = await fetch(endpoint, fetchArgs || {});

		if(!response.ok) {
			throw new Error(response.status);
		}

		return await response.text();
	} catch (error) {
		AnalyticsEvent.sentry(error.message, error)
		return null;
	}
}
