// Javascript for charity details form
// @author Matthew Norris

import Form     from 'Scripts/common/form';
import InputUrl  from 'Scripts/common/input-url';
import Select2  from 'Scripts/common/select2.js';
import Spectrum from 'Vendor/spectrum-colorpicker';
import TextAreaExpand from 'Scripts/common/text-area-expand';
import TomSelect from 'Scripts/common/tom-select';
import UriAvailabilityChecker from 'Scripts/donate/uri-availability-checker';

export default class CharityDetails {
	constructor() {
		this.form = new Form({
			form: $("#form-charity-details"),
			callback: this.submit.bind(this),
			willRedirect: true,
		});

		new Select2(".js-select2");

		$("#colour").spectrum({
			showInput: true,
			chooseText: 'OK',
			localStorageKey: "cp",
			preferredFormat: "hex3",
		});

		// URI checker
		new UriAvailabilityChecker('.js-uri-lookup', '/charity/details/uri-availability', 3); // minLength should match CorporateDetailsForm.java

		// This makes <textarea> inputs expand as you type
		new TextAreaExpand();

		// Setup country dropdown
		new TomSelect("#country");

		// Setup open input values as URLs
		new InputUrl(".js-input-url");
	}

	submit() {
		history.scrollRestoration = 'manual';
		location.reload();
	}
};