import LoadingButton from 'Scripts/common/loading-button';
import fetchHtml from 'Scripts/common/fetch-html';

export default class StreamingToolsModal {
	constructor() {
		this.ui = {
			modal: $('#modal-streaming'),
			modalBody: $('.js-modal-streaming-body'),
		};

		if(!this.ui.modal.length) {
			console.warn('No streaming tools page modal found on page, is it included?');
			return;
		}

		this.bindEvents();
	}

	bindEvents() {
		// Bind events to the document, so it works for stuff loaded via AJAX.
		$(document).on('click', '.js-streaming-modal-trigger', this.onOpenModal.bind(this));
	}

	async onOpenModal(e) {
		e.preventDefault() // Enable it to be used on <a> elements.

		const clickedEl = $(e.currentTarget);
		const id = clickedEl.data('id');

		console.log("StreamingToolsModal.onOpenModal", id);

		if(!id) {
			console.warn('No "data-id" attribute found on modal trigger... not doing anything.');
			return;
		}

		const triggerBtn = new LoadingButton(clickedEl);
		triggerBtn.disable();

		const html = await fetchHtml("/streaming/modal?id=" + id);

		if(html.length) {
			this.ui.modalBody.html(html);
			this.ui.modal.modal('show');
		}

		triggerBtn.enable();
	}
}